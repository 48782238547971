import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient) { }

  private genders: any[] = [
    { genderName: 'Male' },
    { genderName: 'Female' },
    { genderName: 'Other' }
  ];

  private gendersSource = new BehaviorSubject<any[]>(this.genders);
  genders$ = this.gendersSource.asObservable();

  updateGenders(newGenders: any[]) {
    this.genders = newGenders;
    this.gendersSource.next(this.genders);
  }

  getGenders(): any[] {
    return this.genders;
  }

  uploadFile(data: any): Observable<any> {
    return this.http.post(`${environment.bookingApiUrl}/file_upload`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  private showHeaderSubject = new BehaviorSubject<boolean>(true);
  showHeader$ = this.showHeaderSubject.asObservable();

  setShowHeader(value: boolean): void {
    this.showHeaderSubject.next(value);
  }

  private showFooterSubject = new BehaviorSubject<boolean>(true);
  showFooter$ = this.showFooterSubject.asObservable();

  setShowFooter(value: boolean): void {
    this.showFooterSubject.next(value);
  }
}

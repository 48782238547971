<div class="trpx-activity-cards">
    <div class="row">
        <div class="col-6 col-md-3" *ngFor="let card of activityCards">

            <div class="trpx-activity-card" (mouseover)="showInfoText[card.id] = true"
                (mouseleave)="showInfoText[card.id] = false">
                <a href="{{openLink(card.productSlug)}}" target="_blank">
                    <div *ngIf="card?.imageUrl[0]?.length" class="trpx-activity-image"
                        [ngStyle]="{background: 'url('+ card?.imageUrl[0] + ')' + 'no-repeat center / cover,#cccccc'}">
                        <div class="starred-div heading_16" *ngIf="card?.tag">{{card?.tag}}</div>
                    </div>
                    <div *ngIf="!card?.imageUrl[0]?.length" class="trpx-activity-image"
                        [ngStyle]="{background: 'url('+ 'assets/images/empty.webp' + ')' + 'no-repeat center / cover,#cccccc'}">
                        <div class="starred-div heading_16" *ngIf="card?.tag">{{card?.tag}}</div>
                    </div>
                    <div class="trpx-activity-content pointer">
                        <div class="heading_20 height_50 color_15">
                            <!-- {{card?.name.length > 22 ? card.name.slice(0, 22) + '...': card.name}} -->
                            {{card?.name}}
                        </div>
                        <div class="trpx-activity-star-rating">
                            <span>
                                <img src="./assets/icons/Star.svg" class="img-fluid" alt="">
                            </span>
                            <span class="heading_18 color_15">{{card?.rating}}</span>
                            <!-- <span class="description_14 mt-1">({{card?.review}} Reviews)</span> -->
                        </div>
                        <div class="trpx-activity-labels" *ngIf="card?.attributes?.length">
                            <span *ngFor="let subCards of card?.attributes">
                                {{subCards}}
                            </span>
                        </div>
                        <div class="position_bottom_price">
                            <div class="mb-1 description_14">Starting From</div>
                            <div class="prices heading_20 color_15">{{card?.price}}
                                <span class="description_14">{{card?.slashedPrice}}</span>
                            </div>
                        </div>
                    </div>
                </a>
                <!-- <div *ngIf="card?.productInfo && showInfoText[card.id]" class="info-text" [style.left.px]="2" [style.right.px]="2" [style.top.px]="10">
                   {{card?.productInfo}}
                </div> -->
            </div>
        </div>
        <div class="col-6 col-md-3" *ngIf="!activityCards?.length">
            <span>
                No data to show
            </span>
            <!-- <div class="trpx-activity-card" >
                <div  class="trpx-activity-image"
                    [ngStyle]="{background: 'url('+ 'assets/images/empty.webp' + ')' + 'no-repeat center / cover,#cccccc'}">
                    <div class="starred-div heading_16" ></div>
                </div>
                <a>
                    <div class="trpx-activity-content pointer">
                        <div class="heading_20 height_50">
                            </div>
                        <div class="trpx-activity-star-rating">
                            <span class="heading_20"></span>
                            <span class="description_16"></span>
                        </div>
                        <div class="trpx-activity-labels">
                            <span>
                              No data to show
                            </span>
                        </div>
                        <div class="mb-1 description_16"></div>
                        <div class="prices heading_20">
                        </div>
                    </div>
                </a>
            </div> -->
        </div>
    </div>
</div>

<div class="trpx-activity-cards d-none">
    <div class="row">
        <div class="col-md-12 pe-0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let card of activityCards" class="slide">
                    <div class="trpx-activity-card" (mouseover)="showInfoText[card.id] = true"
                        (mouseleave)="showInfoText[card.id] = false">
                        <a href="{{openLink(card.productSlug)}}" target="_blank">
                            <div class="trpx-activity-image"
                                [ngStyle]="{background: 'url('+card?.image_url + ')' + 'no-repeat center / cover,#cccccc'}">
                                <div class="starred-div heading_16" *ngIf="card?.tag">{{card?.tag}}</div>
                            </div>
                            <div class="trpx-activity-content">
                                <div class="heading_20">{{card?.name}}</div>
                                <div class="trpx-activity-star-rating">
                                    <span>
                                        <img src="./assets/icons/Star.svg" class="img-star" alt="">
                                    </span>
                                    <span class="heading_20">{{card?.rating}}</span>
                                    <!-- <span class="description_16">({{card?.review}} Reviews)</span> -->
                                </div>
                                <div class="trpx-activity-labels">
                                    <!-- <span *ngFor="let subCards of card?.attributes">
                                    {{subCards}}
                                </span> -->
                                </div>
                                <div class="mb-1">From</div>
                                <div class="prices heading_20">{{card?.price}}
                                    <!-- <span class="description_16">₹{{card?.new_price}}</span> -->
                                </div>
                            </div>
                        </a>
                        <!-- <div *ngIf="card?.productInfo && showInfoText[card.id]" class="info-text" [style.left.px]="2" [style.right.px]="2" [style.top.px]="10">
                             {{card?.productInfo}}
                        </div> -->
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class attractionService {

    constructor(private http: HttpClient) { }

    getAttractionPassPrice(id: string): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/attraction-passes/${id}`);
    } 
    
    getCouponSuggestion(): Observable<any> {
        return this.http.get(`${environment.bookingApiUrl}/coupon-suggestion`);
    }

    postAttractionPass(data: any): Observable<any> {
        return this.http.post(`${environment.bookingApiUrl}/purchase-attraction-pass`, data);
    }

    attractionPass = gql` query attractionPass($slug: String!, $currency: String!){
        attractionPass(slug: $slug, currency: $currency){
            id
            description
            attributes
            validFrom
            validTo
            price
        }
    }`
    attractionPassBanner = gql` query attractionPassBanner($attractionPassId: String!){
        attractionPassBanner(attractionPassId:$attractionPassId){
            image
        }
    }`
    attractionPassDescription = gql` query attractionPassDescription($attractionPassId: String!){
        attractionPassDescription(attractionPassId:$attractionPassId){
            id
            description
            heading
        }
    }`
    attractionProducts = gql` query attractionProducts($attractionPassId: String!, $currency: String!,$limit: Int!,$offset: Int!){  
            attractionProducts(attractionPassId:$attractionPassId,currency: $currency,limit: $limit,offset: $offset,){
              id
              name
              imageUrl
              rating
              price
              productSlug
              slashedPrice
            }
    }`
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest, takeWhile } from 'rxjs';
import { BookingService } from 'src/app/service/booking.service';
import { HomeService } from 'src/app/service/home.service';
import { PaymentService } from 'src/app/service/payment.service';

@Component({
  selector: 'trpx-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  upcomingBookingData: any[] = [];
  completedBookingData: any[] = [];

  upcomingItemsToDisplay = 10;
  completedItemsToDisplay = 10;

  bookingItems: any;
  componentData: any[] = [];
  isLoading: boolean = false;

  activeTab: string = '';
  upcomingTab: boolean = true;
  completedTab: boolean = false;

  selectedCountry!: string | null;
  selectedCurrency!: string | null;

  booking_idPass: any;
  isDownloading: boolean = false;

  private dataSubscription: Subscription = new Subscription();

  constructor(private bookingService: BookingService, public homeService: HomeService, private toastr: ToastrService, private paymentService: PaymentService) {
    this.selectedCountry = localStorage.getItem('Trip_Country') ?? '';
    this.selectedCurrency = localStorage.getItem('Trip_Currency') ?? '';

    // this.dataSubscription = this.homeService.country$.subscribe((data) => {
    //   if (data) {
    //     this.selectedCountry = data;
    //     if (this.selectedCountry && this.selectedCurrency) this.countryRelatedAPIs();
    //   }
    // });

    // this.dataSubscription = this.homeService.currency$.subscribe((data) => {
    //   if (data) {
    //     this.selectedCurrency = data;
    //     if (this.selectedCurrency && this.selectedCurrency) this.countryRelatedAPIs();
    //   }
    // });
    this.dataSubscription = combineLatest([
      this.homeService.country$,
      this.homeService.currency$
    ]).pipe().subscribe(([country, currency]) => {
      if (country && currency) {
        this.selectedCountry = country;
        this.selectedCurrency = currency;
        this.countryRelatedAPIs();
      }
    });
  }

  ngOnInit(): void {
    this.countryRelatedAPIs();
    this.activeTab = 'upcoming';
  }

  upComingTab() {
    this.activeTab = 'upcoming';
    this.upcomingTab = true;
    this.completedTab = false;
  }

  bookedTab() {
    this.activeTab = 'completed';
    this.upcomingTab = false;
    this.completedTab = true;
  }


  getBookingDetails(currency: any, status: any) {
    this.isLoading = true;
    this.bookingService.getBookingDetails(currency, status).subscribe(
      result => {
        if (status == 'completed') {
          this.completedBookingData = result;
          this.completedBookingData.forEach((item, index) => {
            item.isOpen = index === 0;
          });
          this.isLoading = false;
        }
        if (status == 'upcoming') {
          this.upcomingBookingData = result;
          this.upcomingBookingData.forEach((item, index) => {
            item.isOpen = index === 0;
          });
          this.isLoading = false;
        }

      },
      error => {
        this.isLoading = false;
      })
  }


  toggleAccordion(item: any): void {
    item.isOpen = !item.isOpen;
  }

  // @HostListener('window:scroll', ['$event'])
  // onScroll() {
  //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 450) {
  //     // Check if the user has scrolled to the bottom of the page
  //     this.loadMoreData(this.selectedCurrency);
  //   }
  // }

  // loadMoreData(currency: any) {
  //   if (!this.isLoading) {
  //     this.isLoading = true;
  //     // Perform your data fetching operation, for example, an HTTP request
  //     // Example HTTP request using HttpClient in Angular
  //     // Replace this with your actual data fetching logic
  //     this.bookingService.getBookingDetails(currency).subscribe((newData: any[]) => {
  //       this.bookingData = this.bookingData.concat(newData); // Append new data to existing data
  //       this.isLoading = false;
  //     }, (error) => {
  //       console.error('Error fetching data', error);
  //       this.isLoading = false;
  //     })
  //   }
  // }

  // downloadTicket(booking_id: any) {
  //   this.isDownloading = true;
  //   this.paymentService.downloadTicket(booking_id, this.selectedCountry, this.selectedCurrency).subscribe(
  //     (blob: Blob) => {
  //       this.isDownloading = false;
  //       const blobUrl = URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = blobUrl;
  //       link.download = `Ticket-${this.booking_idPass}.pdf`;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(blobUrl);
  //     },
  //     () => {
  //       this.isDownloading = false;
  //     }
  //   )
  // }

  downloadTicket(booking_id: any) {
    this.isDownloading = true;
    this.paymentService.downloadSingleTicket(booking_id, this.selectedCountry, this.selectedCurrency).subscribe(
      (blob: Blob) => {
        this.isDownloading = false;
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `Ticket-${booking_id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      },
      () => {
        this.isDownloading = false;
      }
    )
  }

  downloadAllTicket(booking_id: any): void {
    this.paymentService.downloadZipFile(booking_id, this.selectedCountry, this.selectedCurrency)
      .subscribe((data: Blob) => {
        // Handle the downloaded zip file data here
        const blobUrl = URL.createObjectURL(data);
        // Use the URL to create a link and trigger download
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = `Ticket-${booking_id}.zip`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, error => {
        console.error('Error downloading zip file:', error);
      });
  }

  countryRelatedAPIs() {
    this.getBookingDetails(this.selectedCurrency, 'completed');
    this.getBookingDetails(this.selectedCurrency, 'upcoming');
  }

  loadMore(type: string) {
    if (type == 'upcoming') this.upcomingItemsToDisplay += 5;
    else this.completedItemsToDisplay += 5;
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
